<template>
  <div class="wrapper">
    <div class='back_cont'>
      <div class="icon_box" @click='back'>
        <van-icon name="arrow-left" :color='"#313131"' class='backicon' :size='"24px"'/>
      </div>
      <div>肿瘤筛查</div>
      <div class="kf"></div>
    </div>
    <div class='header_cont'></div>

<!--    <div class="list_box">-->
<!--      <van-tabs-->
<!--          v-model:active="active"-->
<!--          color="#00C291"-->
<!--          duration="0"-->
<!--          sticky="true"-->
<!--          offset-top="45px"-->
<!--          title-active-color = "#00C291"-->
<!--          title-inactive-color="#999999"-->
<!--          @change="onTabChange"-->
<!--          @click-tab="onClickTab"-->
<!--      >-->
<!--        <van-tab-->
<!--            v-for="(item,index) in serveType.target"-->
<!--            :key="item.desc"-->
<!--            :title="item.desc"-->
<!--            title-class="tit"-->
<!--        >-->
<!--          <div class="list">-->
<!--            <div class="cont"-->
<!--                 v-for="(item,index) in serveList[active]"-->
<!--                 @click="toGoodsDetail(item)"-->
<!--            >-->
<!--              <div class="img_box">-->
<!--                <img :src="item.pic" alt="">-->
<!--              </div>-->
<!--              <div class="serve_text">-->
<!--                <div>-->
<!--                  <div class="t1">{{item.name}}</div>-->
<!--                  <div class="t2">{{item.descrip}}</div>-->
<!--                </div>-->
<!--                <div class="price_t4">-->
<!--                  <div class="left_p">-->
<!--                    <span class="price_1">￥</span>-->
<!--                    <span class="price_2">{{item.singlePrice}}</span>-->
<!--                    <span class="price_3">/次</span>-->
<!--                  </div>-->
<!--                  <div class="btn_buy" v-if="item.subscribeNum">-->
<!--                    <van-icon name="friends" size="15" color="#d1d1d1"/><span>{{item.subscribeNum}}人已购买</span>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->

<!--            </div>-->
<!--          </div>-->
<!--        </van-tab>-->
<!--      </van-tabs>-->
<!--    </div>-->

    <div class="list_box">

            <div class="head_img">
              <img src="https://static.yihu365.cn/img/h5Img/banner_faiz.png" alt="">
            </div>
            <div class="list">
                <div class="cont"
                     v-for="(item,index) in serveList.target"
                     @click="toGoodsDetail(item)"
                >
                    <div class="img_box">
                        <img :src="item.pic" alt="">
                    </div>
                    <div class="serve_text">
                        <div class="t1">{{item.name}}</div>
                        <div class="t2">{{item.descrip}}</div>
                        <div class="price_t4">
                            <div>
                                <span class="price_1">￥</span>
                                <span class="price_2">{{item.singlePrice}}</span>
                                <span class="price_3">/次</span>
                            </div>
                        </div>
                    </div>
                    <div class="right">
                        <van-icon name="arrow" size="18" color="#666666"/>
                    </div>
                </div>
            </div>
  </div>
  </div>
</template>

<script>
import {useRouter, useRoute} from 'vue-router'
import {onMounted, ref, computed, reactive, toRefs} from "vue";
import {useStore} from 'vuex'
import homeApi from '@axios/home'
export default {
  name: "cancerList",
  setup() {
    let router = useRouter()
    const store = useStore()
    const route = useRoute()
    const active = ref(0);
    const serveType = reactive([]);
    const serveList = reactive([]);


    const getList = (index) => {
      let formData = new FormData();
      formData.append('data', JSON.stringify({
        function:'item',
        source:'h5_users_002',
        service_code:'',
        version : store.state.version,
        newversion: store.state.newversion,
        role_code:'002',
        type_code:'004'
      }));
      formData.append('encryption', false)
      homeApi.getList(formData).then((res) => {
        serveList.target = res.data;
      })

    }
    getList()


    const onClickTab = (event) => {
      console.log("1111")
    }


    const toGoodsDetail = (item) => {
        router.push({
          name: 'goodsDetail',
          query: {
            rolecode: item.roleCode,
            firsvcode: item.firSvCode,
            scdsvcode:'',
            serviceType:"079"
          }
        })
      }

   const back = ()=> {
      console.log('111')
      window.location.href ='http://m.yihu365.cn/public.shtml';
   }
    return {
      onClickTab,
      // onTabChange,
      toGoodsDetail,
      serveList,
      back
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.wrapper{
  width: 100%;
  height:100%;

  .back_cont{
    width: 100%;
    position: fixed;
    display: flex;
    //justify-content: center;
    align-items: center;
    justify-content: space-between;
    height: 88px;
    line-height: 88px;
    border-bottom: 1px solid #F5F5F5;
    box-sizing: border-box;
    font-size: 34px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #313131;
    background: #fff;
    z-index: 9999;
    .icon_box{
      margin-left: 10px;
      box-sizing: border-box;
      padding-top: 12px;
    }
    .backicon{
      //position: absolute;
      //left: 15px;
      //width: 20px;

    }
  }
  .kf{
    width: 10px;
  }
}
.list_box {
  .head_img{
    height:284px;
    border-radius: 10px;
    padding:34px 0 20px 0;
    img{
      width: 100%;
      box-sizing: border-box;

      border-radius: 10px;
    }
  }


  width: 100%;
  min-height:100%;
  background:#F8F8F8;
  box-sizing: border-box;
  padding: 0 20px 40px 20px;
  //background-image:linear-gradient(to bottom,#FEFEFE 0%,#F8F8F8 140px,#F2F2F2 50%);
  ::v-deep(.van-sticky){
    background:#F8F8F8;
  }
  ::v-deep(.van-tab){
    flex: none;
    margin-right: 10px;
  }


  ::v-deep(.van-tabs--line .van-tabs__wrap){
    .van-tabs__nav{
      background: none;
    }
    .van-tabs__line{
      width: 50px;
    }
    .van-tabs__nav--line{
      padding-bottom: 20px;
    }
    .van-tab--active{
      font-size: 29px;
    }

  }
  .tit {
    font-size: 30px;
    color: red;
  }

  .list {
    min-height: 600px;
    font-size: 24px;
    .cont {
      background: #FFFFFF;
      display: flex;
      box-sizing: border-box;
      padding: 20px;
      border-radius:20px;
      margin-bottom: 20px;
      //border-bottom: 1px solid #999999;
      .img_box {
        width: 200px;
        height: 200px;
        margin-right: 24px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 15px;
        }
      }

      .serve_text {
        box-sizing: border-box;
        width:450px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .t1 {
          margin-top: 10px;
          font-size: 30px;
          font-weight: 500;
          color: #313131;
          line-height: 38px;
        }

        .t2 {
          margin: 10px 0 10px 0;
          font-size: 25px;
          font-weight: 400;
          color: #666666;
          //height:90px;
          display: -webkit-box;
          overflow: hidden;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .t3 {
          height: 60px;
          span {
            display: inline-block;
            padding: 0 10px;
            height: 40px;
            border: 1px solid #00c291;
            border-radius: 4px;
            color: #00c291;
            text-align: center;
            line-height: 40px;
            margin-right: 12px;

          }
        }

        .price_t4 {
          font-family: PingFangSC-Semibold, PingFang SC;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          .left_p{
            margin-bottom: -3px;
          }
          .price_1 {
            color: #FF6161;
            font-size: 26px;
            font-weight: 600;
          }

          .price_2 {
            color: #FF6161;
            font-size: 38px;
            font-weight: 600;
          }

          .price_3 {
            color: #FF6161;
            font-size: 26px;
          }
          .btn_buy {
            font-size: 24px;
            color: #999999;
            margin-right: 10px;
            .van-icon{
              vertical-align: middle;
            }
            span{
              margin-left: 5px;
              vertical-align: middle;
            }
          }
        }
      }

    }
  }
}
//
//.list {
//    min-height: 600px;
//    font-size: 24px;
//    background: #ffffff;
//    .cont {
//        display: flex;
//        padding: 25px 0 30px 25px;
//        border-bottom: 1px solid #E0E0E0;
//        align-items: center;
//        .img_box {
//            width: 200px;
//            height: 200px;
//            margin-right: 20px;
//            img {
//                width: 100%;
//                height: 100%;
//            }
//        }
//
//        .serve_text {
//            width:450px;
//            display: flex;
//            flex-direction: column;
//            box-sizing: border-box;
//            .t1 {
//                /*margin-top:20px;*/
//                font-size: 30px;
//                font-weight: 400;
//                color: #313131;
//                line-height: 38px;
//            }
//
//            .t2 {
//                margin: 10px 0 10px 0;
//                font-size: 24px;
//                font-weight: 400;
//                color: #919090;
//                height:100px;
//                overflow: hidden;
//                text-overflow: ellipsis;
//            }
//
//            .price_t4 {
//                font-family: PingFangSC-Semibold, PingFang SC;
//                display: flex;
//                justify-content: space-between;
//                .price_1 {
//                    color: #FF6161;
//                    font-size: 24px;
//                    font-weight: 600;
//                }
//
//                .price_2 {
//                    color: #FF6161;
//                    font-size: 34px;
//                    font-weight: 600;
//                }
//
//                .price_3 {
//                    color: #919090;
//                    font-size: 24px;
//                }
//
//                .btn_buy {
//                    width: 139px;
//                    height: 44px;
//                    background: #00C291;
//                    border-radius: 44px;
//                    font-size: 24px;
//                    color: #ffffff;
//                    line-height: 44px;
//                    text-align: center;
//                    margin-right: 26px;
//                }
//            }
//        }
//
//    }
//}
</style>

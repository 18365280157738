import { render } from "./cancerList.vue?vue&type=template&id=5115888e&scoped=true"
import script from "./cancerList.vue?vue&type=script&lang=js"
export * from "./cancerList.vue?vue&type=script&lang=js"

import "./cancerList.vue?vue&type=style&index=0&id=5115888e&rel=stylesheet%2Fscss&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-5115888e"
/* hot reload */
if (module.hot) {
  script.__hmrId = "5115888e"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('5115888e', script)) {
    api.reload('5115888e', script)
  }
  
  module.hot.accept("./cancerList.vue?vue&type=template&id=5115888e&scoped=true", () => {
    api.rerender('5115888e', render)
  })

}

script.__file = "src/views/home/list/cancerList.vue"

export default script